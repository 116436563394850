import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import withRouter from "./withRouter";

class Toolbar extends React.Component {
    render() {
        return (
            <div>
                <div className="d-flex justify-content-between m-3 gap-3 align-items-center">
                    <img src={require("../Assets/Logo.png")} alt="hostel" style={{ maxWidth: "50px" }} />
                    <div style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => { localStorage.removeItem("token"); window.location.reload() }}>
                        Logout
                    </div>
                </div>
                <div className="mx-5">
                    <div className="col-lg-3 col-12">
                        <Nav fill pills>
                            <NavItem>
                                <NavLink active={this.props.router.location.pathname === "/inventory"} href="/inventory">
                                    Inventory
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={this.props.router.location.pathname === "/reservation"} href="/reservation">
                                    Reservation
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Toolbar)