import React from "react"
import Moment from "react-moment"
import { Input, Label, Button, Modal, ModalHeader, ModalBody } from "reactstrap"
import AddInventory from "./AddInventory"
import axios from "axios"
import { baseUrl } from ".."
import { convertExceltoData, convertRoomToData, roomCalculation1 } from "../Utils/roomCalculation"
import * as XLSX from "xlsx"

class InventoryList extends React.Component {
    constructor() {
        super()
        this.state = {
            searchInput: "",
            daysInMonth: 0,
            isAddModal: false,
            noOfEntries: 1,
            currentPage: 1,
            totalPages: 0,
            allInventory: [],
            fromDate: "",
            toDate: "",
            roomCalculation: [],
            today: "",
            last: ""
        }
    }

    componentDidMount() {
        const { noOfEntries } = this.state
        const headers = {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`
            }
        }
        axios.get(`${baseUrl}/inventory/all`, headers).then(response => {
            const allInventory = roomCalculation1(response.data)
            const daysInMonth = allInventory[0]?.availableRooms.length
            const totalPages = allInventory.length / noOfEntries
            const today = new Date()
            const yyyy = today.getFullYear()
            const mm = String(today.getMonth() + 1).padStart(2, '0');
            const dd = today.getDate()
            const lastDay = new Date(yyyy, mm, 0);
            const lastDayOfMonth = String(lastDay.getDate()).padStart(2, '0');
            this.setState({ fromDate: `${yyyy}-${mm}-${String(dd).padStart(2, "0")}`, today: `${yyyy}-${mm}-${String(dd).padStart(2, "0")}` })
            this.setState({ toDate: `${yyyy}-${mm}-${lastDayOfMonth}`, last: `${yyyy}-${mm}-${lastDayOfMonth}` })
            this.setState({ allInventory, daysInMonth, totalPages, roomCalculation: response.data })
        }).catch(err => {
            console.log(err.message)
        })
    }

    render() {

        const onChange = (e) => {
            const { name, value } = e.target
            this.setState({ [name]: value })
        }

        const onChangeDate = (e) => {
            const { name, value } = e.target
            const { fromDate, toDate, roomCalculation } = this.state
            this.setState({ [name]: value }, () => {
                const allInventoryCal = roomCalculation1(roomCalculation, parseInt(fromDate.split("-")[2]), parseInt(toDate.split("-")[2]))
                this.setState({ allInventory: allInventoryCal })
            })
        }

        const onChangeValue = (e) => {
            let { name, value } = e.target
            const { allInventory } = this.state
            const user = name.split("-")
            const [field, date, index] = user
            if (value === "")
                value = 0
            allInventory[index][field][date] = parseInt(value)
            this.setState({ allInventory })
        }

        const onSave = (index) => {
            const { allInventory } = this.state
            const convertedData = convertRoomToData(allInventory[index])
            const headers = {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            }
            convertedData.map(eachData => {
                return axios.put(`${baseUrl}/inventory/${eachData.id}`, eachData, headers).then(response => {
                    console.log(response.status)
                }).catch(err => {
                    console.log(err.message)
                })
            })
        }

        const handleFileUpload = (e) => {
            const file = e.target.files[0];

            if (file && file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = new Uint8Array(e.target.result);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const json = XLSX.utils.sheet_to_json(worksheet);
                    const convertedData = convertExceltoData(json)
                    console.log(convertedData)
                };
                reader.readAsArrayBuffer(file);
            }
        }

        return (
            <div className="mx-5 mt-3">
                <h2 className='fw-bold mb-3'>Inventory</h2>
                <div className="d-flex flex-wrap justify-content-between align-items-center gap-3 mb-5">
                    <div className='d-flex gap-5 align-items-center'>
                        {/* <InputGroup className="border rounded">
                            <InputGroupText className="bg-transparent border-0">
                                <i class="bi bi-search"></i>
                            </InputGroupText>
                            <Input className="border-0" placeholder="Search..." onChange={onChange} value={this.state.searchInput} name="searchInput" />
                        </InputGroup> */}
                        <div>
                            <Label>From</Label>
                            <Input onChange={onChangeDate} value={this.state.fromDate} name="fromDate" type="date" placeholder="Select date" min={this.state.today} />
                        </div>
                        <div>
                            <Label>To</Label>
                            <Input onChange={onChangeDate} value={this.state.toDate} name="toDate" type="date" placeholder="Select date" min={this.state.fromDate} max={this.state.last} />
                        </div>
                    </div>
                    <div className='d-flex gap-3 align-items-center'>
                        <Button onClick={() => this.setState({ isAddModal: true })} className="text-text bg-dark-o border-0" color="success">Add Rooms</Button>
                        <input
                            type="file"
                            accept=".xlsx"
                            onChange={handleFileUpload}
                            style={{
                                padding: '10px',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                cursor: 'pointer'
                            }}
                            id="upload-btn"
                            hidden
                        />
                        <label className="btn bg-secondary-o text-dark-o border-0" color="dark" htmlFor="upload-btn"><i className='bi bi-plus-lg'></i> Bulk Upload</label>
                    </div>
                </div>
                <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                    <table className="inventory-table" style={{ width: "max-content" }}>
                        <thead>
                            <tr>
                                <th className="h2 fw-bold">
                                    <Moment format="MMM">
                                        {new Date()}
                                    </Moment>
                                </th>
                                {Array.from({ length: this.state.daysInMonth }, (_, index) => {
                                    const date = new Date("2024", new Date().getMonth(), index + 1)
                                    if (index >= this.state.fromDate.split("-")[2] && index <= this.state.toDate.split("-")[2])
                                        return (
                                            <td style={{ width: "5rem" }} className="text-nowrap text-center" key={index}>
                                                <div className={new Date().getDate() === date.getDate() ? "text-dark-o fs-4 fw-bold" : "text-secondary-o"}>
                                                    <Moment format="ddd">
                                                        {date.toDateString()}
                                                    </Moment>
                                                </div>
                                                <div className={`text-dark-o ${new Date().getDate() === date.getDate() ? "fs-4 fw-bold" : ""}`}>
                                                    <Moment format="D">
                                                        {date.toDateString()}
                                                    </Moment>
                                                </div>
                                            </td>
                                        )
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.allInventory.map((eachInventory, index) => {
                                return ["Name", "Available Rooms", "Booked Rooms", "Current Price"].map(eachRow => {
                                    if (eachRow === "Name")
                                        return (
                                            <tr key={eachRow.hostelName} aria-colspan={this.state.daysInMonth}>
                                                <th className="d-flex gap-3 align-items-center my-3">
                                                    <div className="text-tertiary-o fs-5 fw-bold text-nowrap">{eachInventory?.hostelName}</div>
                                                    <div>
                                                        <Button onClick={() => onSave(index)} className="bg-dark-o text-text" size="sm">
                                                            Save
                                                        </Button>
                                                    </div>
                                                </th>
                                            </tr>
                                        )
                                    if (eachRow === "Available Rooms")
                                        return (
                                            <tr className="border">
                                                <th>
                                                    {eachRow}
                                                </th>
                                                {eachInventory.availableRooms.map((eachItem, dateIndex) => {
                                                    if (dateIndex >= this.state.fromDate.split("-")[2] && dateIndex <= this.state.toDate.split("-")[2])
                                                        return (
                                                            <td style={{ width: "5rem" }} className="text-nowrap text-center" key={`${eachItem}-${dateIndex}`}>
                                                                <Input disabled={dateIndex + 1 < new Date().getDate()} onChange={onChangeValue}
                                                                    value={this.state.allInventory[index].availableRooms[dateIndex]} name={`availableRooms-${dateIndex}-${index}`} placeholder="0" className="text-center" />
                                                            </td>
                                                        )
                                                })}
                                            </tr>)
                                    if (eachRow === "Booked Rooms")
                                        return (
                                            <tr className="border">
                                                <th>
                                                    {eachRow}
                                                </th>
                                                {eachInventory.bookedRooms.map((eachItem, dateIndex) => {
                                                    if (dateIndex >= this.state.fromDate.split("-")[2] && dateIndex <= this.state.toDate.split("-")[2])
                                                        return (
                                                            <td style={{ width: "5rem" }} className="text-nowrap text-center" key={`${eachItem}-${dateIndex}`}>
                                                                <Input disabled={true} onChange={onChangeValue}
                                                                    value={this.state.allInventory[index].bookedRooms[dateIndex]} name={`bookedRooms-${dateIndex}-${index}`} placeholder="0" className="text-center" />
                                                            </td>
                                                        )
                                                })}
                                            </tr>)
                                    if (eachRow === "Current Price")
                                        return (
                                            <tr className="border">
                                                <th>
                                                    {eachRow}
                                                </th>
                                                {eachInventory.cost.map((eachItem, dateIndex) => {
                                                    if (dateIndex >= this.state.fromDate.split("-")[2] && dateIndex <= this.state.toDate.split("-")[2])
                                                        return (
                                                            <td style={{ width: "5rem" }} className="text-nowrap text-center" key={`${eachItem}-${dateIndex}`}>
                                                                <Input disabled={dateIndex + 1 < new Date().getDate()} onChange={onChangeValue}
                                                                    value={this.state.allInventory[index].cost[dateIndex]} name={`cost-${dateIndex}-${index}`} placeholder="0" className="text-center" />
                                                            </td>
                                                        )
                                                })}
                                            </tr>)
                                    if (eachRow === "Total Revenue")
                                        return (
                                            <tr className="border">
                                                <th>
                                                    {eachRow}
                                                </th>
                                                {eachInventory.cost.map((eachItem, dateIndex) => {
                                                    return (
                                                        <td style={{ width: "5rem", padding: "1rem" }} className="text-nowrap text-center" key={`${eachItem}-${dateIndex}`}>
                                                            Rs. {eachItem * eachInventory.bookedRooms[dateIndex]}
                                                        </td>
                                                    )
                                                })}
                                            </tr>)
                                    else return (<div></div>)
                                })

                            })}
                        </tbody>
                    </table>
                </div>
                <div className="mt-3 d-flex justify-content-center gap-3 align-items-center">
                    <div className="d-flex gap-2 align-items-center">
                        <span>Show</span>
                        <Input value={this.state.noOfEntries} onChange={onChange} name="noOfEntries" type="select">
                            {[1, 2, 5, 10].map(eachValue => {
                                return (
                                    <option value={eachValue} key={eachValue}>
                                        {eachValue}
                                    </option>
                                )
                            })}
                        </Input>
                        <span>entries</span>
                    </div>
                    <button onClick={() => this.setState({ currentPage: this.state.currentPage - 1 })} className="border-0 bg-transparent" disabled={this.state.currentPage === 1}>
                        Previous
                    </button>
                    {Array.from({ length: this.state.totalPages }, (_, i) => i + 1).map(eachPage => {
                        return (
                            <div key={eachPage}>
                                <Button onClick={() => this.setState({ currentPage: eachPage })}
                                    className={`page-button ${this.state.currentPage === eachPage ? 'active' : ''}`}>
                                    {eachPage}
                                </Button>
                            </div>
                        )
                    })}
                    <button onClick={() => this.setState({ currentPage: this.state.currentPage + 1 })}
                        className="border-0 bg-transparent" disabled={this.state.currentPage === this.state.totalPages}>
                        Next
                    </button>
                </div>
                <Modal isOpen={this.state.isAddModal} toggle={() => this.setState({ isAddModal: false })}>
                    <ModalHeader toggle={() => this.setState({ isAddModal: false })}>Add Inventory</ModalHeader>
                    <ModalBody>
                        <AddInventory />
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

export default InventoryList